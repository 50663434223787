import {ajax as $ajax} from "@/utils/PHttpUtil";
// eslint 회피를 위해 PayApp 전역객체를 변수에 저장
// eslint-disable-next-line
let PAY_APP = PayApp;

const payapp = {

    /**
     * @param params
     * @param params.goodName
     * @param params.price
     * @param params.useDepositPoint
     * @param params.useRewardPoint
     * @param params.outboundReqDate
     * @param params.userTelNo
     * @param params.payInfos[] {paymentBoxId, couponId, userTelNo, countryTelCd}
     * }
     * */
    payForDelivery: (params) => {
        const {goodName, price, userTelNo, payInfos} = params;
        const feedbackSubUrl = "/public/Bdr/Payapp/feedback/delivery";
        setBaseParams();
        setEnvParam(feedbackSubUrl);
        PAY_APP.setParam('goodname', goodName);
        PAY_APP.setParam('price', price);
        let countryTelCd = payInfos[0].countryTelCd;
        const isNotKoreaTelCd = countryTelCd !== "82";
        if (isNotKoreaTelCd) {
            PAY_APP.setParam('vccode', countryTelCd);
        }
        PAY_APP.setParam('recvphone', userTelNo);

        saveVar1(params, (tempId) => {
                /* eslint-disable no-console */
                PAY_APP.setParam('var1', tempId); // [{boxId:1,shipperType:"EXP"}, {boxId:2,shipperType:"EMS"}]
                PAY_APP.call();
            }
        )
    },

    payForDeposit: ({price, userCountryTelCd, userTelNo, userId, returnUrlQuery =''}) => {
        const feedbackSubUrl = "/public/Bdr/Payapp/feedback/deposit";
        setBaseParams();
        setEnvParam(feedbackSubUrl, returnUrlQuery);
        PAY_APP.setParam('goodname', "보따리 예치금 충전");
        PAY_APP.setParam('price', price);
        // PAY_APP.setParam('price', 1000);

        const isNotKoreaTelCd = userCountryTelCd !== "82";
        if (isNotKoreaTelCd) {
            PAY_APP.setParam('vccode', userCountryTelCd);
        }
        PAY_APP.setParam('var1', userId);
        PAY_APP.setParam('recvphone', userTelNo);
        PAY_APP.call();
    }

}


const setBaseParams = () => {
    PAY_APP.setDefault('shopname', '보따리');
    PAY_APP.setTarget("_self");
    PAY_APP.setParam('smsuse', 'n'); // 결제요청 SMS 발송여부 ('n'인 경우 SMS 발송 안함)
    PAY_APP.setParam('checkretry', 'n'); // feedbackurl의 응답이 'SUCCESS'가 아닌 경우 feedbackurl 호출을 재시도 합니다. (총 10회)
    PAY_APP.setParam('skip_cstpage', 'n'); // 매출전표 페이지 없이 returnUrl으로 post 방식으로 이동
    PAY_APP.setParam('redirectpay', '1');
    PAY_APP.setParam('currency', 'krw'); // 통화단위 krw 원화 결제 고정
    // 결제방법 제한시 필요
    // (휴대전화:phone, 신용카드:card, 계좌이체:rbank, 가상계좌:vbank, 카카오페이:kakaopay, 네이버페이:naverpay, 스마일페이:smilepay, 애플페이:applepay, 페이코:payco, 위챗페이:wechat)
    PAY_APP.setParam('openpaytype', 'card,naverpay,kakaopay,smilepay,payco');
}

const setEnvParam = (feedbackSubUrl, returnUrlQuery ='') => {

    const currentURL = window.location.href.lastIndexOf('?') > 0
        ? window.location.href.substring(0, window.location.href.lastIndexOf('?'))
        : window.location.href;

    let serverBaseURL;
    if (currentURL.includes('localhost')) {
        // 로컬 개발환경
        serverBaseURL = 'http://db.perform.co.kr:19087'
        // PAY_APP.setParam('userid', "payapptest");
        PAY_APP.setParam('userid', "ksinc");
    } else if (currentURL.includes('perform') || currentURL.includes('stg')) {
        // todo
        // 개발서버 도메인
        serverBaseURL = 'https://7dayis.perform.co.kr'
        PAY_APP.setParam('userid', "ksinc");
        // PAY_APP.setParam('userid', "payapptest");
    } else {
        // todo
        // 운영 서버 결정시
        // serverBaseURL = "??"
        PAY_APP.setParam('userid', "ksinc");
    }

    console.log(currentURL + returnUrlQuery)

    PAY_APP.setParam('returnurl', currentURL + returnUrlQuery);
    PAY_APP.setParam('feedbackurl', serverBaseURL + feedbackSubUrl);
}

/**
 @param params.useDepositPoint
 @param params.useRewardPoint
 @param params.outboundReqDate
 @param params.payInfos[] {paymentBoxId, couponId}
 */
const saveVar1 = (params, callback) => {
    $ajax({
        public: true,
        url: "/Bdr/Payapp/save/var1",
        params: {
            depositPoint: params.useDepositPoint,
            rewardPoint: params.useRewardPoint,
            outboundReqDate: params.outboundReqDate,
            payInfos: params.payInfos,
        },
        callback
    });
}

export { payapp }