<!--
Usage
  PSpinner AJAX 통신시 로딩 스피너 컴포넌트

ex)
  Vue.prototype.$spinner(true) -> 스피너 활성화

-->

<template>
  <div v-if="loading" no-close-on-backup="true">
    <div tabindex="-1" role="dialog" class="modal overflow-auto d-block modal-info p-spinner">
      <div class="modal-backdrop fade"></div>
      <PulseLoader :loading="loading" :color="COLORS.main" size="30px"/>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import {COLORS} from "@/constants/color";

let __PSpinner_instance = null

export default {
  name: 'PSpinner',
  computed: {
    COLORS() {
      return COLORS
    }
  },
  components: {
    PulseLoader,
  },
  data() {
    return {
      loading: false,
      lock: false,
    }
  },
  methods: {
    show() {
      this.loading = true
      this.$emit('initLoad');
    },
    hide() {
      this.loading = false
      this.$emit('endLoad');
    }
  },
  mounted() {
    __PSpinner_instance = this
  }
};

function spinner(show, force) {
  if(__PSpinner_instance) {
    if(show) {
      __PSpinner_instance.show()
      if(force) {
        __PSpinner_instance.lock = true
      }
    }
    else if(!show && (!__PSpinner_instance.lock || force)){
      __PSpinner_instance.hide()
      if(force) {
        __PSpinner_instance.lock = false
      }
    }
  }
}

export { spinner }

</script>

<style scoped>
.v-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -55px;
  width: 110px;
  height: 30px;
}

</style>
