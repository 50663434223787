<template>
    <!-- > 페이지 확인용 -->
    <div class="tnb wrap">
        <div class="container">
            <div class="pc_view">
                <!--
                <i><img src="@/assets/img/common/award.png" alt="상장"></i>-->
                <span class="txt"><img src="@/assets/img/common/tnb_txt.png" alt="보따리 익스프레스"></span>
            </div>
            <img src="@/assets/img/common/m_award.png" alt="보따리 익스프레스" class="m_view">
        </div>
    </div>

    <header class="header wrap">
        <div class="container">
            <h1 class="m_logo"><router-link to="/company"><img src="@/assets/img/common/c_logo.png" alt="보따리 익스프레스"></router-link></h1>

                <nav class="gnb">
                    <Gnb />
                </nav>


            <div class="right">
                <div class="company" v-if="userInfo && userInfo.userNm"><i class="fas fa-building"></i><strong>{{ userInfo.userNm }}</strong>님</div>
                <div class="toggle"><a href=""><img src="@/assets/img/common/toggle.png" alt="토글"></a></div>
            </div>
        </div>
        <All />
    </header>
</template>

<script>
import Gnb from './Gnb.vue';
import All from './AllGnb.vue';

export default {
    name: 'main-header',
    components: {
        'Gnb':Gnb,
        'All':All,
    },
    data() {
        return {
            userInfo: null,
        };
    },
    mounted() {

        this.userInfo = this.$session.load('userInfo');
    }
}
</script>