/*
Usage
    this.$ajax - AJAX 통신 (기본적으로 보안을 위해 post Method만 지원)
    options
     - url: WAS controller 에 Mapping 될 path (필수)
     - public: login 화면 등 session 정보가 없는 상태의 호출되는 ajax 만 true (default: false)
     - params: 쿼리에 필터링 조건이 필요한경우 object 타입으로 전달 (default: {})
     - callback: 결과값 받은 후 처리할 콜백함수 등록

    this.$print - 체크된 행들의 Jasper Report 출력(PDF)
    this.$singlePrint - 포커싱된 행의 단건 Jasper Report 출력(PDF)
ex)
    this.$ajax({
        url: '/CommonCode',
        params: {commCodeCd: 'USER_DIV'},
        callback: (result) => {
           console.log(result)
    })
*/
import { session as $session } from "@/utils/PStorageUtil";
import { spinner as $spinner } from "@/components/PSpinner";
import { constants as $globals } from "@/utils/PGlobalConstants";
import { utils as $utils, modal as $modal } from "@/utils/PWebUtil";
import { useGlobals } from "@/main";

const axios = require("axios");
const axios2 = require("axios");

axios.defaults.withCredentials = true;
axios.defaults.timeout = 60000;
{
  if (process.env.VUE_APP_WAS === "local") {
    axios.defaults.baseURL =
      window.location.protocol +
      "//" +
      window.location.hostname +
      ":" +
      $globals.serverPort;
  } else if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = "https://7dayis.perform.co.kr/";
  } else if (process.env.NODE_ENV === "production") {
    axios.defaults.baseURL = "https://7dayis.perform.co.kr/";
  }
}

const ajax = (options) => {
  let _options = {
    url: (options.public ? "/public" : "/auth") + options.url,
    params: options.params || {},
    config: options.config || {},
    callback: options.callback,
    error: options.error,
  };

  $spinner(true);

  let errorHandler = (error) => {
    /* eslint-disable no-console */
    console.log(typeof error);
    $spinner(false, true);
    if (error.code === 401) {
      $modal({
        color: "danger",
        title: "오류",
        content: "로그인 세션이 만료되었습니다.",
        callback: () => {
          $session.delete("userInfo");

          // router push 이용시 헤더정보가 감지가 되지 않는 문제가 있어 강제 새로고침을 위한 location.href 이용
          location.href = "/login";
        },
      });
    } else if (error.code === 409) {
      $modal({
        color: "danger",
        title: "오류",
        content: error.message,
        callback: () => {
          $utils.logout();
        },
      });
    } else {
      const globals = useGlobals();
      const errorMessage =
        globals.$i18n.global.t(error.message) || error.message;

      $modal({
        color: "danger",
        title: "오류",
        content: errorMessage,
        callback: () => {
          if (_options.error) {
            _options.error();
          }
        },
      });
    }
  };

  if (!options.config) {
    // multipart 등 params 를 있는 그대로 넘겨야 할 경우를 제외하고는 값이 비어있는 키는 제거하여 전달
    _options.params = $utils.clone(_options.params);
  }

  axios
    .post(_options.url, _options.params, _options.config)
    .then((response) => {
      $spinner(false);
      if (_options.config.responseType === "blob") {
        if (response.data.type.includes("application/json")) {
          let blob = new Blob([response.data], { type: "application/json" });
          let fr = new FileReader();
          fr.onload = function () {
            errorHandler(JSON.parse(this.result));
          };
          fr.readAsText(blob);
        } else if (
          response.data.type.includes("application/pdf") ||
          response.data.type.includes("application/x-pdf")
        ) {
          //console.log(response)
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          let printWindow = window.open(
            url,
            "",
            "width=1200, height=1000, toolbar=no, menubar=no, scrollbars=no, resizable=yes"
          );
          printWindow.print();
          //printWindow.close()
          if (_options.callback instanceof Function) {
            _options.callback(response.data.result);
          }
        }
      } else {
        if (response.data.code === 0) {
          if (_options.callback instanceof Function) {
            _options.callback(response.data.result);
          }
        } else {
          errorHandler(response.data);
        }
      }
    })
    .catch((error) => {
      $spinner(false, true);
      $modal({
        color: "danger",
        title: "오류",
        content: error.message,
      });
      // console.log(error.request)
      // console.log(error.config)
      // console.log(error.response)
      // console.log(error.message)
    });
};

const fileDownload = (options) => {
  let _options = {
    url: (options.public ? "/public" : "/auth") + options.url,
    params: options.params || {},
    config: options.config || {},
    callback: options.callback,
    error: options.error,
  };

  if (options.url === "/Bdr/Login/getCaptchaImage") {
    _options.config.responseType = "blob"; // This ensures the response is treated as a binary blob
  }

  $spinner(true);
  if (!options.config) {
    _options.params = $utils.clone(_options.params);
  }

  axios2
    .post(_options.url, _options.params, _options.config)
    .then((response) => {
        // console.log(response);
        $spinner(false);
      if (_options.config.responseType === "blob" && !response.headers.expires) {
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          _options.params.rawFileName || "downloaded-file"
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      } else if (response.headers.expires) {
        if (_options.callback instanceof Function) {
          _options.callback(response.data);
        }
      } else {
        if (response.data.code === 0) {
          if (_options.callback instanceof Function) {
            _options.callback(response.data.result);
          }
        }
      }
    })
    .catch((error) => {
      $spinner(false, true);
      if (error.code === "ERR_BAD_RESPONSE") {
        $modal({
          color: "danger",
          title: "오류",
          content: "파일을 찾을 수 없습니다.",
        });
      } else {
        $modal({
          color: "danger",
          title: "오류",
          content: error.message,
        });
      }
    });
};

const print = (params) => {
  if (params.chkRows < 1) {
    $modal({
      content: "선택된 행이 없습니다.",
    });
    return;
  }

  if (params.programPath) {
    params.programPath = $utils.pascalToSnake(params.programPath);
  }
  if (params.chkRows instanceof Array) {
    let makeId = $utils.isNotNull(params.keyCol) && params.keyCol !== "linkId";
    let makeSeq =
      $utils.isNotNull(params.keySeq) && params.keySeq !== "linkSeq";
    if (makeId || makeSeq) {
      let _chkRows = [];
      for (let row of params.chkRows) {
        let data = {};
        if (makeId) data.linkId = row[params.keyCol];
        if (makeSeq) data.linkSeq = row[params.keySeq];
        else if (row.linkSeq) data.linkSeq = row.linkSeq;
        _chkRows.push(data);
      }
      params.chkRows = _chkRows;
    }
  }

  ajax({
    url: "/Common/report",
    params: params,
    config: {
      responseType: "blob", // important
    },
    callback: params.callback,
  });
};

const singlePrint = (params) => {
  params.chkRows = [params.data];
  delete params.data;
  print(params);
};

const get = (option) => {
  let url = option.url;
  if (option.params) {
    let param = "";
    for (let key in option.params) {
      if (param === "") {
        param += "?";
      } else {
        param += "&";
      }
      param += key + "=" + option.params[key];
    }
    url += param;
  }

  $spinner(true);

  fetch(url, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((data) => {
      $spinner(false);
      if (option.callback instanceof Function) {
        option.callback(data);
      }
    })
    .catch((error) => {
      $spinner(false, true);
      $modal({
        color: "danger",
        title: "오류",
        content: error.message,
      });
    });
};

const post = (option) => {
  $spinner(true);

  fetch(option.url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(option.params),
  })
    .then((response) => response.json())
    .then((data) => {
      $spinner(false);
      if (option.callback instanceof Function) {
        option.callback(data);
      }
    })
    .catch((error) => {
      $spinner(false, true);
      $modal({
        color: "danger",
        title: "오류",
        content: error.message,
      });
    });
};

export { ajax, print, singlePrint, get, post, fileDownload };
