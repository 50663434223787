<template>

    <ul class="de1_ul">
        <li class="de1_li"><router-link :to="{name: 'osa', query: {'depth': 1}}" class="de1_a arrN"><span class="bln" role="link"><img src="@/assets/img/common/mg_icon01.png" alt="토글">이용가이드</span></router-link>
            <ul class="de2_ul">
                <li class="de2_li"><router-link to="/user/guide/service/osa" class="de2_a">보따리 서비스</router-link></li>
                <li class="de2_li"><router-link to="/user/guide/prohibit" class="de2_a">금지 및 제한품목</router-link></li>
                <li class="de2_li"><router-link to="/user/guide/packing" class="de2_a">패킹서비스 안내</router-link></li>
            </ul>
        </li>
        <li class="de1_li"><router-link to="/user/asked/ask_main" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon02.png" alt="토글">신청하기</span></router-link>
            <ul class="de2_ul">
                <li class="de2_li"><router-link to="/user/asked/ask_main" class="de2_a">신청하기</router-link></li>
                <li class="de2_li"><router-link to="#;" class="de2_a">신청서작성가이드</router-link></li>
            </ul>
        </li>
        <li class="de1_li"><router-link to="/user/ship/stock/stockList/1" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon03.png" alt="토글">배송현황</span></router-link>
            <ul class="de2_ul">
                <li class="de2_li"><router-link to="/user/ship/stock/stockList/1" class="de2_a">입고현황</router-link></li>
                <li class="de2_li"><router-link to="/user/ship/release/releaseList/1" class="de2_a">출고현황</router-link></li>
                <li class="de2_li"><router-link to="/user/ship/pay_list/1" class="de2_a">결제현황</router-link></li>
            </ul>
        </li>
        <li class="de1_li"><router-link to="#;" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon04.png" alt="토글">마이페이지</span></router-link>
            <ul class="de2_ul">
                <li class="de2_li"><router-link to="/user/mypage/member" class="de2_a">기본정보</router-link></li>
                <li class="de2_li"><router-link to="/user/mypage/address/addrLIst_in" class="de2_a">주소록</router-link></li>
                <li class="de2_li"><router-link to="/user/mypage/wallet/point/1" class="de2_a">내지갑</router-link></li>
            </ul>
        </li>
        <li class="de1_li"><router-link to="/user/cs/notice/nlist" class="de1_a"><span class="bln"><img src="@/assets/img/common/mg_icon05.png" alt="토글">고객센터</span></router-link>
            <ul class="de2_ul">
                <li class="de2_li"><router-link to="/user/cs/notice/nlist/1" class="de2_a">공지사항</router-link></li>
                <li class="de2_li"><router-link to="/user/cs/event/elist/1" class="de2_a">이벤트</router-link></li>
                <li class="de2_li"><router-link to="/user/cs/faq" class="de2_a">FAQ</router-link></li>
                <li class="de2_li">  <a href="https://m.cafe.naver.com/ca-fe/web/cafes/18936622/menus/28" target="_blank" class="de2_a">이용후기</a></li>
                <li class="de2_li"><router-link to="/user/cs/through" class="de2_a">통관정보</router-link></li>
                <li class="de2_li"><router-link to="/user/cs/unconfirmed" class="de2_a">고객미확인물품</router-link></li>
                <li class="de2_li"><router-link to="/user/cs/fba" class="de2_a">대량/FBA 문의</router-link></li>
                <li class="de2_li"><router-link to="/user/cs/oto/1" class="de2_a">1:1문의</router-link></li>
            </ul>
        </li>
    </ul>


</template>

<script>
    export default {
        name: 'Gnb_basic',
        
    }
</script>
