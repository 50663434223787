<template>
  <div class="p-modal-container">
    <div class="p-modal-header">
        {{title}}
    </div>
    <div class="p-modal-content" style="white-space: pre-line">
        {{content}}
    </div>
    <div class="p-modal-footer" style="gap: 1rem">
      <button v-for="button in buttons" :key="button.name" class="p-modal-button"
              :style="{
                      backgroundColor: button.bgColor,
                      color: button.color,
                      borderColor: button.borderColor,
                      padding: 0
                    }"
              @click="() => closeAndCall(button.callback)"
      >{{ button.name }}
      </button>
    </div>
  </div>
</template>
<script>


const __PModalPopup_default_color = '#888'
const __PModalPopup_default_bg_color = '#FFFFFF'
const __PModalPopup_default_border_color = '#888'
const __PModalPopup_default_title = '알림'
const __PModalPopup_default_content = '처리완료'
const __PModalPopup_default_button_name = '확인'
const __PModalPopup_default_button_variant = 'outline'

import {unmountModal as $unmount} from "@/utils/PWebUtil";

export default {
  name: 'PModalPopup',
  props: {
    content: {
      type: String,
      default: __PModalPopup_default_content,
    },
    color: {
      type: String,
      default: __PModalPopup_default_color,
    },
    size: {
      type: String,
      default: 'sm'
    },
    title: {
      type: String,
      default: __PModalPopup_default_title,
    },
    buttons: {
      type: Array,
      default: () => [{
        name: __PModalPopup_default_button_name,
        color: __PModalPopup_default_color,
        variant: __PModalPopup_default_button_variant,
        borderColor: __PModalPopup_default_border_color,
        bgColor: __PModalPopup_default_bg_color,
        callback: null
      }],
    },
    callback: Function,
  },
  methods: {
    closeAndCall(callback) {
      if (callback) {
        callback()
      }

      this.close()
    },
    close() {
      if (this.callback) {
        this.callback()
      }

      $unmount();
    },
  },
  mounted() {
  }
};

</script>
<style scoped>

.p-modal-container {
  background-color: #fdfdfd;
  margin: 15% auto; /* 모달을 화면 중앙에 위치 */
  border: 1px solid #888;
  width: 80%; /* 모달의 너비 조절 */
  max-width: 300px; /* 모달의 최대 너비 설정 */
  position: relative; /* 내부 요소들이 절대 위치를 사용할 때 필요 */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}

.p-modal-header {
  padding: 10px;
  height: 3rem;

  background-color: #d92b33;
  font-size: 2rem;
  color: white;
}

.p-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;

  min-height: 5rem;
  padding: 5px;
  width: calc(100% - 10px);
  outline: 0;

  background-color: #fff;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  font-size: 1.5rem;
}

.p-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.p-modal-button{
  font-size: 1.5rem;
  height: 3rem;
  width: 6rem;
}
</style>
